import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Hi! 👋`}</h2>
    <p>{`I'm Horacio Herrera, Designer & Developer consultant from Panama🇵🇦 living in Barcelona. I help companies develop digital products that users want.`}</p>
    <p>{`Currently I'm building `}<a parentName="p" {...{
        "href": "https://mintter.com"
      }}>{`Mintter`}</a>{`, a document publishing platform on top of Bitcoin ⚡️`}</p>
    <p>{`You can find me in the internets on `}<a parentName="p" {...{
        "href": "https://twitter.com/hhg2288"
      }}>{`twitter`}</a>{`, `}<a parentName="p" {...{
        "href": "https://twitch.tv/horacioh"
      }}>{`twitch`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.youtube.com/channel/UC-MHIQayBciRoA4HXx873jg"
      }}>{`youtube`}</a>{`, `}<a parentName="p" {...{
        "href": "https://github.com/horacioh"
      }}>{`github`}</a>{`, `}<a parentName="p" {...{
        "href": "https://dribbble.com/horacio"
      }}>{`dribbble`}</a>{` & `}<a parentName="p" {...{
        "href": "https://codesandbox.io/u/horacioh"
      }}>{`codesandbox`}</a>{`. You can also send me an `}<a parentName="p" {...{
        "href": "mailto:hi+contact@horacioh.com"
      }}>{`email`}</a>{` if you prefer.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      